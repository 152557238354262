import React, { useEffect, useState } from "react";

const TransactionHistory = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [rowcount, setRowcount] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const columns = [
    {
      dataIndex: "transactionType",
      title: "Transaction Type",
      render: (text) => {
        return <span className="table-primary-text">{text || "-"}</span>;
      },
    },
    {
      dataIndex: "amount",
      title: "Transaction Amt.",
      render: (text) => {
        return <>{text?.cur() || "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Date & Time",
      render: (text, record) => {
        if (text) {
          let date = new Date(text)?.toDate();
          let time = new Date(text)
            .toLocaleTimeString()
            .replace(/:\d{2}/, "")
            .toLowerCase();
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "details",
      title: "Details",
    },
  ];

  const onPageChange = (pageData) => {
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const getTransactionHistoryData = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetPastTransaction(payload).then(({ data, count }) => {
      setData(data);
      setRowcount(count);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    getTransactionHistoryData();
  }, [search]);

  return (
    <>
      <AppTable
        data={data}
        columns={columns}
        onChange={(...arg) => onPageChange(...arg)}
        total={rowcount}
        reorder={true}
        viewable={false}
        editable={false}
        deletable={false}
        loading={isFetching}
      ></AppTable>
    </>
  );
};

export default constore(TransactionHistory);
