import React, { Component } from "react";
import { Link } from "react-router-dom";
export class NotificationsSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {};
    this.fields = [
      {
        name: "name",
        placeholder: "Notification Name",
        type: "text",
      },
      {
        name: "startDate",
        label: "Start Date",
        type: "date",
        placeholder: "Start Date",
      },
      {
        name: "endDate",
        label: "End Date",
        type: "date",
        placeholder: "End Date",
      },
      {
        name: "status",
        label: "Status",
        type: "select",
        options: [],
        placeholder: "Status",
      },
    ];
  }

  componentDidMount() {}

  onSearch(reset = false) {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    let { role } = this.props?.$store?.user | {};
    let fields = this.fields;

    return (
      <AppForm
        className="filter-row flex-auto mx-auto digi_team_member_search"
        ref="form"
      >
        <div className="d-none col-sm-6 col-md-2 col-lg-4"></div>
        {fields.map((field, i) => (
          <div className="col-6 col-md-4 col-lg-3 col-xl-2" key={i}>
            <AppInputFocus ref="inputname" {...field}></AppInputFocus>
          </div>
        ))}
        <div className="col-3 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-lg-50">
          <button
            className="btn btn-primary search_btn"
            onClick={() => this.onSearch()}
          >
            <i className="fa fa-search"></i>
            <span>Search</span>
          </button>
        </div>
        <div className="col-5 col-md-1 col-lg-1 col-xl-1 d-flex align-items-start justify-content-center mw-75 mw-md-50">
          <button
            type="button"
            className="btn btn-primary clear_filter_btn"
            onClick={(...args) => this.resetSearchForm(...args)}
          >
            <i class="fa-solid fa-filter-circle-xmark"></i>
          </button>
        </div>
        <div className="col-7 col-sm-6 col-lg-4 col-xl-2 d-flex align-items-start justify-content-xl-center">
          <div className="digi-vertical-line ms-0 me-2 me-md-2"></div>
          <Link
            to={"#"}
            className="btn btn-primary mb-3 add_digi_team_member_btn"
            onClick={(...args) => this.props.onAddModalFields(...args)}
          >
            + Add New
          </Link>
        </div>
      </AppForm>
    );
  }
}

export default constore(NotificationsSearch);
