import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import NotificationsModal from "./NotificationsModal";
import NotificationsSearch from "./NotificationsSearch";
import { Modal } from "react-bootstrap";

const Notifications = (props) => {
  const isAdmin = props?.$store?.user?.role === _enum.ROUTE_PERSONAS.admin;
  var FILE_DOWNLOAD_BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [totalPageChange, setTotalPageChange] = useState({
    page: 1,
    limit: 10,
    // order_by: "updatedAt",
    // order_direction: "DESC",
  });
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [stateCity, setStateCity] = useState({ state: "", city: "" });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modalFields, setModalFields] = useState([
    {
      label: "Header",
      name: "header",
      type: "text",
      placeholder: "Please Enter",
      // required: true,
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      placeholder: "Start Date",
      // required: true,
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      placeholder: "End Date",
      // required: true,
    },
    {
      name: "image",
      label: "Image",
      type: "file",
      fileLabel: "Image",
      foldername: "notifications",
      accept: ".png, .jpg, .jpeg",
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      placeholder: "Enter here",
      customcolspan: "12",
    },
  ]);
  const [editData, setEditData] = useState({});
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});

  const [loader, setLoader] = useState(false);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "header",
      title: "Name",
      render: (text, record) => {
        return (
          <>
            <span className="table-primary-text">{text}</span>
          </>
        );
      },
    },
    {
      dataIndex: "startDate",
      title: "Start Date",
      render: (text) => {
        let dt = new Date(text);
        return dt.dateToDDMMYYYY(dt);
      },
    },
    {
      dataIndex: "createdAt",
      title: "Created On",
      render: (text) => {
        let dt = new Date(text);
        return dt.dateToDDMMYYYY(dt);
      },
    },
    {
      dataIndex: "attachment",
      title: "Attachment",
      render: (text) => {
        return (
          <a href="#" class="table-primary-text link_btn">
            Link
          </a>
        );
      },
    },
    {
      dataIndex: "description",
      title: "Description",
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        return (
          <span className={`badge light badge-${_enum.STATUS_COLOR_MAP[text]}`}>
            {text?.toTitleCase() || "-"}
          </span>
        );
      },
    },
  ];

  const onPageChange = (pageData) => {
    // console.log({ onPageChange: pageData });
    setTotalPageChange({
      ...search,
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 25,
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const getNotifications = (params = {}, reset) => {
    console.log({ params, reset });
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "limit",
        "page",
        // "order_by",
        // "order_direction",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      // console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    // props.apis.apiGetAdminNotificationsMoke(payload).then(({ data, total }) => {
    //   console.log({ apiGetDigirep: data, total });
    //   data = data?.map((obj) => {
    //     let newObj = { ...obj };
    //     newObj.joiningDate = new Date(obj.joiningDate).toDate();
    //     return newObj;
    //   });
    //   setTableData(data);
    //   setIsFetching(false);
    //   setRowcount(total);
    // });

    props.apis.apiGetAdminNotificationsMoke().then((res) => {
      setTableData(res);
      setIsFetching(false);
    });
  };

  const deleteNotification = (record) => {
    console.log({ deleteNotification: record });
    // swal2
    //   .fire({
    //     title: "Are you sure you want to delete this digirep?",
    //     icon: "info",
    //     iconColor: "#234C9B",
    //     showCancelButton: true,
    //     confirmButtonColor: "#234C9B",
    //     cancelButtonColor: "##D6DBE7",
    //     confirmButtonText: "Yes",
    //     cancelButtonText: "No",
    //   })
    //   .then((result) => {
    //     if (result.isConfirmed) {
    //       props.apis
    //         .apiDeleteEmployee({ profileId: record.profileId })
    //         .then((res) => {
    //           console.log({ apiDeleteEmployee: res });
    //           swal2.fire({
    //             title: "Digi Team Member deleted successfully !!",
    //             icon: "success",
    //             iconColor: "#234C9B",
    //             timer: 4000,
    //           });
    //           getNotifications();
    //         });
    //     }
    //   });
  };

  const viewEmployee = (record) => {
    setEditmode(false);
    setViewmode(true);
    // // GET EMPLOYEE BY ID
    // props.apis
    //   .apiGetEmployeeById({ profileId: record.profileId })
    //   .then((data) => {
    //     console.log({ apiGetEmployeeById: data });
    //     let filteredData = Object.only(
    //       data,
    //       modalFields.map((v) => v.name)
    //     );
    //     console.log({ filteredData });
    //     setPopupdata(filteredData);
    //     setEditData(data);
    //     openModal();
    //     let location = modalFields.find((o) => o.name == "workLocation");
    //     let emailAddressField = modalFields.find(
    //       (o) => o.name == "emailAddress"
    //     );
    //     let mobileNumberField = modalFields.find(
    //       (o) => o.name == "mobileNumber"
    //     );
    //     emailAddressField.disabled = true;
    //     mobileNumberField.disabled = true;
    //     location.defaultValue = data.workLocation;
    //   });
  };

  const editEmployee = (record) => {
    setEditmode(true);
    setViewmode(false);
    // GET EMPLOYEE BY ID
    // props.apis
    //   .apiGetEmployeeById({ profileId: record.profileId })
    //   .then((data) => {
    //     console.log({ apiGetEmployeeById: data });
    //     let filteredData = Object.only(
    //       data,
    //       modalFields.map((v) => v.name)
    //     );
    //     console.log({ filteredData, supervisorOptions });
    //     let alteredData = {
    //       ...filteredData,
    //       workLocation: record.workLocation,
    //     };
    //     setPopupdata(alteredData);
    //     setEditData(data);
    //     openModal();

    //     let location = modalFields.find((o) => o.name == "workLocation");
    //     location.defaultValue = data.workLocation;

    //     let designation = modalFields.find((o) => o.name == "designation");
    //     designation.defaultValue = data.designation;

    //     let supervisor = modalFields.find((o) => o.name == "supervisorId");
    //     // supervisor.options = supervisorOptions;
    //     getSupervisors(data.role);
    //     supervisor.defaultValue = data?.supervisorId;

    //     let emailAddressField = modalFields.find(
    //       (o) => o.name == "emailAddress"
    //     );
    //     let mobileNumberField = modalFields.find(
    //       (o) => o.name == "mobileNumber"
    //     );

    //     emailAddressField.disabled = true;
    //     mobileNumberField.disabled = true;

    //     //
    //     // console.log(data.designation, tableData);
    //     // let tdata;
    //     // switch (data.designation) {
    //     //   case "DigiRep":
    //     //     tdata = tableData.filter(
    //     //       (v) => v.designation === "Manager DigiRep" || v.designation === "Admin"
    //     //     );
    //     //     break;

    //     //   case "Manager DigiRep":
    //     //     tdata = tableData.filter((v) => v.designation === "Admin");
    //     //     break;

    //     //   case "Admin":
    //     //     tdata = tableData.filter((v) => v.designation === "Admin");
    //     //     break;

    //     //   default:
    //     //     tdata = tableData.filter((v) => v);
    //     //     break;
    //     // }
    //     // console.log({ tdata });

    //     // if (tdata) {
    //     //   let supervisorIdField = modalFields.find(
    //     //     (o) => o.name == "supervisorId"
    //     //   );
    //     //   let supervisoroptions = tdata.options("profileId", "name");
    //     //   supervisorIdField.options = supervisoroptions;
    //     //   supervisorIdField.defaultValue = tdata?.supervisor?.profileId;
    //     //   console.log({ supervisoroptions });
    //     //   setModalFields([...modalFields]);
    //     //   setSupervisorOptions(supervisoroptions);
    //     // }
    //   });
  };

  // ----------------------------------------------------------------
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [activeNotificationData, setActiveNotificationData] = useState(null);

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    console.log("formdata: ", data);
    let payload = {
      ...data,
    };

    console.log({ payload });
    setActiveNotificationData(payload);
    setShowPreviewModal(true);

    if (editmode) {
      let updateData = {
        ...payload,
      };
      console.log({ updateData });
      // props.apis.apiUpdateEmployee(updateData).then((res) => {
      //   // console.log({ apiUpdateEmployee: res });
      //   swal2.fire({
      //     title: "Notification Updated Successfully.",
      //     icon: "success",
      //     iconColor: "#234C9B",
      //     timer: 4000,
      //   });
      //   closeModal();
      //   getNotifications();
      // });
    } else {
      // props.apis.apiAddDigirep(payload).then((res) => {
      //   // console.log({ apiAddDigirep: res });
      //   swal2.fire({
      //     title: "Notification Created Successfully.",
      //     icon: "success",
      //     iconColor: "#234C9B",
      //     timer: 4000,
      //   });
      //   closeModal();
      //   getNotifications();
      // });
    }
  };

  const handleNotificationSubmit = () => {
    console.log({ activeNotificationData });
    swal2.fire({
      title: "Notification Created Successfully.",
      icon: "success",
      iconColor: "#234C9B",
      timer: 4000,
    });
    closePreviewModal();
    closeModal();
  };

  // ----------------------------------------------------------------

  const onSearch = (data, reset) => {
    console.log("onSearch on digirep", data, reset);
    if (Object.keys(data).length === 0) {
      setSearch({
        ...data,
        ...search,
      });
      getNotifications(data, reset);
    } else {
      setSearch({
        ...data,
        ...search,
      });
      getNotifications(data, reset);
    }
  };

  const addModalFields = (values) => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    setPopupfields(fields);
    setPopupdata(formdata);
    setOpenCanvas(true);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setActiveNotificationData(null);
  };

  useEffect(() => {
    console.log({ Notifications: props });
    getNotifications();
  }, []);

  useEffect(() => {
    console.log(search);
    getNotifications(totalPageChange);
  }, [totalPageChange]);

  useEffect(() => {
    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    setPopupfields(fields);
  }, [editData]);

  props.$setNode({
    props,
    modalFields,
    editData,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Notifications"
        pageTitle={"Notifications"}
        parentTitle={"Home"}
        pageIcon={imagepaths.BellIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container me-lg-3">
                  <NotificationsSearch
                    inactive={props.inactive}
                    onSearch={(...arg) => onSearch(...arg)}
                    onAddModalFields={(...args) => addModalFields(...args)}
                  />
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editAction={(e) => editEmployee(e)}
                  viewAction={(e) => viewEmployee(e)}
                  deleteAction={(e) => deleteNotification(e)}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationsModal
        id="employeeCanvas"
        title={`${editmode ? "Edit" : viewmode ? "View" : "Add New"}`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Preview"}`}
        disableallfields={viewmode ? true : false}
        loader={loader}
        viewmode={viewmode}
        errorMessage={errorMessage}
      />

      {/* CONSENT OTP MODAL */}
      <Modal
        show={showPreviewModal}
        onHide={closePreviewModal}
        size="lg"
        centered
        backdrop="static"
        className="consent_otp_modal notification_preview_modal"
      >
        <Modal.Header closeButton className="border-0 py-4 text-center">
          <h2 className="mx-auto">{activeNotificationData?.header || "-"}</h2>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center mb-4">
                <p className="date">
                  {activeNotificationData?.startDate || "-"}
                </p>
                <div className="time_box ms-3">
                  <i className="fa fa-clock me-1"></i>
                  <p>
                    {new Date()
                      .toLocaleTimeString()
                      .replace(/:\d{2}/, "")
                      .toLowerCase()}
                  </p>
                </div>
              </div>
              <p className="desc mb-3">
                {activeNotificationData?.description || "-"}
              </p>
              <img
                src={`${FILE_DOWNLOAD_BASE_URL}${
                  activeNotificationData?.image || "-"
                }`}
                alt="preview image"
              />
              <p>Regards,</p>
              <p>Starpowerz Digiloans</p>
            </div>

            <div class="row mx-0 mt-4 bootom_footer_action">
              <div class="col-12 text-center">
                <button
                  type="button"
                  className="btn formdata-cancel-btn"
                  onClick={(e) => closePreviewModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ms-3"
                  onClick={(...args) => handleNotificationSubmit(...args)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(Notifications);
