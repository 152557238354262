import React, { Component } from "react";

export class NotificationsModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.NotificationsModal = this;
  }
  render() {
    let { props } = this;
    let {
      id,
      title,
      show,
      onClose,
      modalSize,
      loader,
      errorMessage,
      viewmode,
    } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppCanvas
        id={id}
        title={title}
        show={show}
        onClose={onClose}
        modalSize={modalSize}
      >
        <AppFormData
          {...attrs}
          onClose={onClose}
          errorMessage={errorMessage}
          viewmode={viewmode}
        ></AppFormData>
        {loader && <div className="loader"></div>}
      </AppCanvas>
    );
  }
}

export default NotificationsModal;
